export function SearchIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24.295}
      height={props.size || 24.295}
      viewBox={`0 0 24.295 24.295`}
      {...props}
    >
      <path
        d="M24.018 22.683l-5.929-5.929a10.24 10.24 0 10-1.336 1.336l5.929 5.929a.945.945 0 101.336-1.336zM1.889 10.226a8.336 8.336 0 118.336 8.336 8.346 8.346 0 01-8.336-8.336z"
        fill={props.color}
      />
    </svg>
  );
}
