import { setShowInstallAppOverlay } from '@/redux/actions';
import { useDispatch, useSelector } from 'react-redux';

function useInstallAppOverlayActions() {
const dispatch = useDispatch();

  const { showInstallAppOverlay } = useSelector((state: any) => ({
    showInstallAppOverlay: state.commonReducer?.showInstallAppOverlay,
  }));

  const openIntallAppOverlay = () => {
    dispatch(setShowInstallAppOverlay(true));
  };

  const closeIntallAppOverlay = () => {
    dispatch(setShowInstallAppOverlay(false));
  };

  return {showInstallAppOverlay, openIntallAppOverlay, closeIntallAppOverlay};
}

export default useInstallAppOverlayActions;
