import React from 'react';
import { StyledBurger } from './index.style';

const Burger = ({
  open,
  setOpen,
  theme,
  style = {},
  showIcon = null,
  showDiffIcon = false,
}) => {
  return (
    <div>
      {showDiffIcon && <div onClick={() => setOpen(!open)}>{showIcon?.()}</div>}
      {!showDiffIcon && (
        <StyledBurger
          style={style}
          open={open}
          onClick={() => setOpen(!open)}
          theme={theme}
          aria-label="menu"
        >
          <div />
          <div />
          <div />
        </StyledBurger>
      )}
    </div>
  );
};

export default Burger;
