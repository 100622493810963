import store from '@/redux/store';
import { getStoreType } from '@/utils/getStoreType';
import { ORDER_TYPE, STORE_CUSTOMIZATION, COD_CHARGE_TYPE } from './constants';

/** This utility function returns custom cta texts, certain labels based on is the store deliverying orders or
 * booking services
 * @param {string} type -> type of text you want
 * @return {string} -> text you wanted
 */
const getCustomCtaText = (type) => {
  const { theme, services } = store.getState().storeReducer.store;
  const isStoreServiceBased =
    getStoreType() !== STORE_CUSTOMIZATION.ORDER_BASED &&
    getStoreType() != STORE_CUSTOMIZATION.B2B;

  const isExtraChargeForCODActive = services?.cod_charge_flag > COD_CHARGE_TYPE.FREE;

  const getBillConfirmedHeading = () => {
    switch (getStoreType()) {
      case STORE_CUSTOMIZATION.ORDER_BASED:
        return 'Order placed successfully!';
      case STORE_CUSTOMIZATION.B2B:
        return 'Request submitted Successfully';
      default:
        return 'Booked Successfully';
    }
  };

  const getBillOrderIdLabel = () => {
    const cart = store.getState().cartReducer;

    switch (cart.order_type) {
      case ORDER_TYPE.SERVICE:
        return 'Your Booking ID:';
      case ORDER_TYPE.B2B:
        return 'Your Request ID:';
      default:
        return 'Your Order ID:';
    }
  };

  switch (type) {
    case 'my_orders_cta':
      if (getStoreType() === STORE_CUSTOMIZATION.B2B) return 'My Requests';
      else if (theme?.cta_config?.my_orders) return theme?.cta_config?.my_orders;
      else if (isStoreServiceBased) return 'My Bookings';
      else return 'My Orders';
    case 'bill_confirm_heading':
      return getBillConfirmedHeading();
    case 'placing_order_cta':
      if (isStoreServiceBased) return 'Booking your Service';
      else return 'Placing Your Order';
    case 'buy_now_cta':
      if (theme?.cta_config?.pdp_cta) return theme?.cta_config?.pdp_cta;
      else if (isStoreServiceBased) return 'Book Now';
      else 'Buy NOW';
    case 'empty_cart_cta':
      return isStoreServiceBased ? 'Book Now' : 'Shop Now';
    case 'banner_cta':
      if (getStoreType() === STORE_CUSTOMIZATION.B2B) return 'My Requests';
      else if (theme?.cta_config?.banner_cta) return theme?.cta_config?.banner_cta;
      else if (isStoreServiceBased) return 'Book Now';
      else return 'Shop Now';
    case 'order_instructions_label':
      if (isStoreServiceBased) return 'Booking Instructions';
      else return 'Order Instructions';
    case 'cart_cta':
      if (theme?.cta_config?.postpaid_cart_cta)
        return theme?.cta_config?.postpaid_cart_cta;
      else if (isStoreServiceBased) return 'Book Now';
      else return 'Place Order';
    case 'item_total_label':
      if (isStoreServiceBased) return 'Total';
      else return 'Item Total';
    case 'order_summary_heading':
      if (isStoreServiceBased) return 'Summary';
      else return 'Order Summary';
    case 'item_details_heading':
      if (isStoreServiceBased) return 'Details';
      else return 'Item Details';
    case 'widget_cta':
      if (isStoreServiceBased) return 'Book Now';
      else return 'Explore Now';
    case 'cart_confirm_cta': {
      const cart = store.getState().cartReducer;
      const isOrderServiceType = cart.order_type === ORDER_TYPE.SERVICE;
      if (isOrderServiceType) return 'Pay after service';
      else if (cart.order_type === ORDER_TYPE.DELIVERY)
        return isExtraChargeForCODActive ? 'Cash On Delivery' : 'Pay On Delivery';
      else return 'Pay on Pickup';
    }
    case 'pay_after_cta':
      if (isStoreServiceBased) return 'Pay after Service using';
      else return 'Pay on Delivery / Pickup using';
    case 'other_payment_modes_cta':
      if (isStoreServiceBased) return 'Pay after service';
      else {
        const { prepaid_flag, order_type } = store.getState().orderReducer.orderDetails;
        if (prepaid_flag === 1) {
          if (order_type === ORDER_TYPE.DELIVERY)
            return isExtraChargeForCODActive ? 'Cash On Delivery' : 'Pay On Delivery';
          else return 'Pay On Pick Up';
        } else return 'Cash On Delivery';
      }
    case 'bill_order_id': {
      return getBillOrderIdLabel();
    }
    case 'order_list_heading':
      return isStoreServiceBased ? 'My Bookings' : 'My Orders';
    default:
      return '';
  }
};

export default getCustomCtaText;
