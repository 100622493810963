export const HeaderArrow = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '7.561'}
      height={props.height || '4.427'}
      viewBox="0 0 7.561 4.427"
    >
      <path
        id="Path_296380"
        fill={props.color || '#000'}
        data-name="Path 296380"
        d="M145.574,181.8l-3.166-3.166a.633.633,0,0,0-.893,0L138.35,181.8a.633.633,0,0,0,.893.893l2.716-2.716,2.722,2.716a.631.631,0,1,0,.893-.893Z"
        transform="translate(-138.197 -178.446)"
      />
    </svg>
  );
};
