/**
 * common logout component. After logout
 * is initiated, if successful
 * reroute to product
 */
import {
  removeSelectedAddress,
  saveAddressList,
  setCart,
  setLogout,
} from '@/redux/actions';
import store from '@/redux/store';
import router from 'next/router';
import { getRoute, PATH } from './routes';

export function executeLogout() {
  const storeData = store?.getState().storeReducer?.store;
  const cartData = store.getState().cartReducer;

  localStorage.clear();
  store.dispatch(setLogout());
  store.dispatch(setCart({ ...cartData, items: [], store_offer: {}, payment_offer: {} }));
  store.dispatch(removeSelectedAddress());
  store.dispatch(
    saveAddressList({
      addressList: [],
      defaultAddress: {},
    })
  );
  router.push(
    getRoute(
      storeData?.theme?.custom_theme_flag ? PATH.PRODUCT : PATH.SHOP,
      storeData?.store_info?.domain
    )
  );
}
