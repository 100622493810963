export const HeaderInfoIcon = (props) => {
  return (
    <svg
      id="information"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '13.757'}
      height={props.height || '16.008'}
      viewBox="0 0 13.757 16.008"
    >
      <g id="Group_160946" data-name="Group 160946" transform="translate(0 0)">
        <g id="Group_160945" data-name="Group 160945">
          <path
            id="Path_229366"
            fill={props.color || '#000'}
            data-name="Path 229366"
            d="M234.921,182.774c-.4,0-.68.168-.68.415v3.366c0,.212.283.424.68.424.38,0,.689-.212.689-.424v-3.366C235.611,182.942,235.3,182.774,234.921,182.774Z"
            transform="translate(-228.043 -177.06)"
          />
          <path
            id="Path_229367"
            fill={props.color || '#000'}
            data-name="Path 229367"
            d="M233.553,116.361a.637.637,0,1,0,.716.627A.684.684,0,0,0,233.553,116.361Z"
            transform="translate(-226.674 -112.723)"
          />
          <path
            id="Path_229368"
            fill={props.color || '#000'}
            data-name="Path 229368"
            d="M42.878,0A6.875,6.875,0,0,0,40.8,13.428l1.564,2.306a.625.625,0,0,0,1.035,0l1.564-2.306A6.875,6.875,0,0,0,42.878,0ZM44.4,12.29a.625.625,0,0,0-.349.251l-1.172,1.727-1.172-1.727a.625.625,0,0,0-.349-.251,5.628,5.628,0,1,1,3.042,0Z"
            transform="translate(-36 0)"
          />
        </g>
      </g>
    </svg>
  );
};
