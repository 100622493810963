import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { saveHeaderHeight, saveHeaderRef } from '../redux/actions';

function useSaveHeaderHeight(ref) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(saveHeaderRef(ref));
    dispatch(saveHeaderHeight(ref.current?.offsetHeight));
  }, [ref?.current]);
}

export default useSaveHeaderHeight;
