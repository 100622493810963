export const UserIconDark = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '33'}
      height={props.height || '33'}
      viewBox="0 0 33 33"
    >
      <g
        id="Group_160983"
        data-name="Group 160983"
        transform="translate(-1002.137 -16.628)"
      >
        <g id="User" transform="translate(1002.413 16.903)">
          <circle
            id="Ellipse_5156"
            data-name="Ellipse 5156"
            cx="16.5"
            cy="16.5"
            r="16.5"
            transform="translate(-0.275 -0.275)"
            fill="#efefef"
          />
          <g
            id="Group_160982"
            data-name="Group 160982"
            transform="translate(3.728 6.554)"
          >
            <path
              id="Path_296382"
              data-name="Path 296382"
              d="M32.084,44.26a15.588,15.588,0,0,0-24.972-.008,15.539,15.539,0,0,0,24.972.008Z"
              transform="translate(-7.112 -24.671)"
              fill={props.color || '#464e58'}
            />
            <circle
              id="Ellipse_5157"
              data-name="Ellipse 5157"
              cx="5.896"
              cy="5.896"
              r="5.896"
              transform="translate(6.593 0)"
              fill={props.color || '#464e58'}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
