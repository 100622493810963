export const isOutsideClick = (parentRef, target) => {
  if (parentRef.current?.contains(target)) return true;
  return false;
};

export const commonOutsideClick = ({ event, pathNo = 0, ref, callback }) => {
  const path = event.path || (event.composedPath && event.composedPath());
  if (!isOutsideClick(ref, path[pathNo])) {
    callback && callback();
  }
};
