export const HeaderShoppingCart = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '15.228'}
      height={props.height || '15.603'}
      viewBox="0 0 15.228 15.603"
    >
      <g id="shopping-cart-10" transform="translate(0.075 0.075)" opacity="0.997">
        <path
          id="Path_296381"
          fill={props.color || '#000'}
          data-name="Path 296381"
          d="M14.625,2.625a1.927,1.927,0,0,0-1.483-.693H2.731L2.7,1.706A1.932,1.932,0,0,0,.787,0H.644a.644.644,0,0,0,0,1.288H.787a.644.644,0,0,1,.639.568L2.312,9.39a3.219,3.219,0,0,0,3.2,2.844h6.724a.644.644,0,1,0,0-1.288H5.51A1.932,1.932,0,0,1,3.694,9.658h7.675A3.219,3.219,0,0,0,14.538,7.01l.505-2.8a1.928,1.928,0,0,0-.419-1.581Zm-.845,1.353-.506,2.8a1.932,1.932,0,0,1-1.9,1.589H3.489L2.883,3.219H13.142a.644.644,0,0,1,.637.758Z"
          stroke="#fff"
          strokeWidth="0.15"
        />
        <circle
          id="Ellipse_5154"
          fill={props.color || '#000'}
          data-name="Ellipse 5154"
          cx="1.288"
          cy="1.288"
          r="1.288"
          transform="translate(3.219 12.878)"
          stroke="#fff"
          strokeWidth="0.15"
        />
        <circle
          id="Ellipse_5155"
          fill={props.color || '#000'}
          data-name="Ellipse 5155"
          cx="1.288"
          cy="1.288"
          r="1.288"
          transform="translate(9.658 12.878)"
          stroke="#fff"
          strokeWidth="0.15"
        />
      </g>
    </svg>
  );
};
