export const ContactUs = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '24.001'}
      height={props.height || '24.07'}
      viewBox="0 0 15.001 15.07"
    >
      <path
        id="whatsapp-9"
        d="M12.858,2.155A7.35,7.35,0,0,0,1.293,11.022L.25,14.83l3.9-1.022a7.341,7.341,0,0,0,3.512.894h0a7.352,7.352,0,0,0,5.2-12.547ZM7.66,13.461h0a6.1,6.1,0,0,1-3.109-.851l-.223-.132-2.312.606.617-2.254L2.486,10.6A6.108,6.108,0,1,1,7.66,13.461Zm3.35-4.574c-.184-.092-1.086-.536-1.255-.6s-.291-.092-.413.092-.474.6-.581.72-.214.138-.4.046a5.015,5.015,0,0,1-1.477-.911A5.539,5.539,0,0,1,5.865,6.964c-.107-.184,0-.274.081-.375a5.194,5.194,0,0,0,.459-.628.338.338,0,0,0-.015-.322c-.046-.092-.413-1-.566-1.363s-.3-.31-.413-.315-.229-.006-.352-.006a.674.674,0,0,0-.49.23,2.059,2.059,0,0,0-.643,1.532,3.572,3.572,0,0,0,.75,1.9,8.185,8.185,0,0,0,3.137,2.772,10.51,10.51,0,0,0,1.047.387,2.517,2.517,0,0,0,1.156.073,1.892,1.892,0,0,0,1.239-.873,1.534,1.534,0,0,0,.107-.873C11.317,9.025,11.194,8.979,11.011,8.887Zm0,0"
        transform="translate(-0.108 0.1)"
        stroke="#fff"
        strokeWidth="0.2"
        fillRule="evenodd"
      />
    </svg>
  );
};
