import React from 'react';
import Modal from '../Common/Modal';
import { deviceWidth } from '@/utils/deviceWidth';
import { MAX_MOBILE_WIDTH } from '../../utils/constants';
import { useSSRSelector } from '@/redux/ssrStore';

function LogoutModal(props) {
  const { showLogoutConfirmationModal } = useSSRSelector((state) => ({
    showLogoutConfirmationModal: state.commonReducer.showLogoutConfirmationModal,
  }));

  return deviceWidth > MAX_MOBILE_WIDTH ? (
    <LogoutDesktopModal
      {...props}
      showLogoutConfirmationModal={
        showLogoutConfirmationModal || props.showLogoutConfirmation
      }
    />
  ) : (
    <LogoutMobileModal
      {...props}
      showLogoutConfirmationModal={
        showLogoutConfirmationModal || props.showLogoutConfirmation
      }
    />
  );
}

function LogoutMobileModal(props) {
  const executeLogout = () => {
    props.executeLogout();
    props?.toggleLogoutModal();
  };

  return (
    <>
      <Modal
        visible={props.showLogoutConfirmationModal}
        className="mobile-center-modal margin-top-modal-0 !tw-z-[700]"
        animation={'slideUp'}
        width={85}
        height={150}
      >
        <div className="logout-modal-content-container flex flex-column !tw-z-[700]">
          <div className="flex justify-center fw7">
            <span
              style={{
                color: 'black',
                fontSize: 16,
                transform: 'translate(10px , 11px)',
                whiteSpace: 'nowrap',
              }}
            >
              Are you sure you want to Logout?
            </span>
          </div>
          <div
            className="flex flex-row justify-end"
            style={{ marginTop: 16, transform: 'translateY(24px)' }}
          >
            <div
              className="flex no-container items-center justify-center bg-black-90 br4"
              onClick={props?.toggleLogoutModal}
            >
              <span className="white fw6">No</span>
            </div>
            <div
              className="flex yes-container items-center justify-center br4 bg-white-90"
              onClick={executeLogout}
            >
              <span className="br4 black fw6">Yes</span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

function LogoutDesktopModal(props) {
  const executeLogout = () => {
    props.executeLogout();
    props?.toggleLogoutModal();
  };

  return (
    <>
      <Modal
        visible={props.showLogoutConfirmationModal}
        className="bottom-modal"
        animation={'slideUp'}
        width={30}
        height={150}
      >
        <div className="logout-modal-content-container flex flex-column">
          <div className="flex justify-start">
            <h3 style={{ color: 'black', marginLeft: 10 }}>
              Are you sure you want to Logout?
            </h3>
          </div>
          <div className="flex flex-row justify-end mt4">
            <div
              className="flex no-container items-center justify-center bg-black-90 br4"
              onClick={props?.toggleLogoutModal}
            >
              <span className="white fw6">No</span>
            </div>
            <div
              className="flex yes-container items-center justify-center br4 bg-white-90"
              onClick={executeLogout}
            >
              <span className="br4 black fw6">Yes</span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
export default LogoutModal;
