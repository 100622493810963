/* eslint new-cap: ["error", { "newIsCap": false }]*/
import MegaMenuMobileView from '@/components/MegaMenu/MegaMenuMobileView';
import { showMegaMenu } from '@/utils/showMegaMenu';
import { IS_DESKTOP } from '@/utils/checkClientDeviceType';
import { isPLPRequiredSystem } from '@/utils/isPLPRequiredSystem';
import { removeEmoticons } from '@/utils/emoticonsStatus';
import { getRoute, PATH } from '@/utils/routes';
import { SupermarketIcon } from '@/assets/svgExports/SupermarketIcon';
import { HeaderInfoIcon } from '@/assets/svgExports/HeaderInfoIcon';
import { HeaderHome } from '@/assets/svgExports/HeaderHome';
import router from 'next/router';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import getNavList from '@/utils/getNavList';
import { MAX_VISIBLE_NAV_LINKS } from '@/utils/constants';

const NavigationItems = ({
  categories = [],
  horizontalNav = true,
  navigationItems = [],
  isFooter = false,
  closeNavigationOnMobile = undefined,
  showCategories = false,
  headerName = '',
  toggleMegaMenu,
}) => {
  const shopMenuRef = useRef();
  const moreMenuRef = useRef();
  const [open, setOpen] = useState({
    openShop: false,
    openMore: false,
    openMegaMenu: false,
  });

  const { storeData } = useSelector((state) => ({
    storeData: state.storeReducer.store,
  }));

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const navItemsState = getNavList(
    navigationItems,
    MAX_VISIBLE_NAV_LINKS,
    showCategories
  );

  const handleClickOutside = (e) => {
    if (!shopMenuRef?.current?.contains(e.target)) {
      setOpen((state) => ({ ...state, openShop: false }));
    }
    if (!moreMenuRef?.current?.contains(e.target)) {
      setOpen((state) => ({ ...state, openMore: false }));
    }
  };

  const renderDropdownNav = () => {
    return (
      <div className="cfe-dropdown-nav">
        <div className="cfe-dropdown-nav__items">
          {categories.map((category) => (
            <div
              className="cfe-dropdown-nav__item"
              key={category.id}
              onClick={() => onCategoriesLinkHandle(category)}
            >
              {removeEmoticons(category.name) || 'Available Items'}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const navSubList = (nav) => {
    return (
      <div className="cfe-dropdown-nav">
        <div className="cfe-dropdown-nav__items">
          {nav.map((n) => (
            <div
              className="cfe-dropdown-nav__item"
              key={n.page_id}
              onClick={() => onHandlePageClick(n)}
            >
              {n.page_name}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const onCategoriesLinkHandle = (category) => {
    if (isPLPRequiredSystem(storeData?.theme?.theme_class)) {
      router.push(
        getRoute(`${PATH.SHOP}?cid=${category.id}`, storeData?.store_info?.domain),
        undefined,
        { shallow: true }
      );
    } else {
      router.push(
        getRoute(`${PATH.PRODUCT}?cid=${category.id}`, storeData?.store_info?.domain),
        undefined,
        { shallow: true }
      );
    }
    if (open) {
      setOpen(false);
      closeNavigationOnMobile?.();
    }
  };

  const handleShopClickForMegaMenu = () => {
    IS_DESKTOP
      ? toggleMegaMenu && toggleMegaMenu()
      : setOpen((state) => ({
          ...state,
          openMegaMenu: !open.openMegaMenu,
        }));
  };

  const onHandlePageClick = (nav) => {
    IS_DESKTOP && showMegaMenu() && toggleMegaMenu && toggleMegaMenu();
    if (nav.page_type === 'blank_page' && nav.is_external_url) {
      window.open(nav.slug_url, '_blank');
      return;
    }
    if (nav.slug_url === 'shop') {
      showMegaMenu() && !isFooter
        ? handleShopClickForMegaMenu()
        : setOpen((state) => ({
            ...state,
            openShop: !open.openShop,
          }));
    } else if (['more', 'subNavList'].includes(nav.slug_url)) {
      setOpen((state) => ({
        ...state,
        openMore: !open.openMore,
      }));
    } else {
      if (nav.is_editable) {
        window.location.href = getRoute(
          PATH.additionalPage(nav.slug_url),
          storeData?.store_info?.domain
        );
      } else {
        router.push(getRoute(nav.slug_url, storeData?.store_info?.domain));
      }
      closeNavigationOnMobile?.();
    }
  };

  const renderNavType = (nav) => {
    switch (nav.page_type) {
      case 'shop': {
        if (headerName !== 'Header14') {
          return (
            <span
              key={nav.page_id}
              ref={shopMenuRef}
              className="cfeNavItems__item"
              onClick={() => onHandlePageClick(nav)}
            >
              <div>
                <div className="mr3 visible-mobile">
                  <SupermarketIcon
                    color={
                      storeData?.theme?.colors?.primary_color !== '#ffffff'
                        ? '#000000'
                        : '#ffffff'
                    }
                    width={16}
                    height={16}
                  />
                </div>
                {nav.page_name}
                {categories?.length > 0 && (
                  <img
                    src="/assets/images/angleright.png"
                    alt="dropdown button"
                    style={{
                      transform: `rotate(${horizontalNav ? '90deg' : '0deg'})`,
                    }}
                  />
                )}
              </div>
              {open.openShop && renderDropdownNav()}
              {open.openMegaMenu && (
                <MegaMenuMobileView
                  open={true}
                  onClose={() => {
                    if (open) {
                      setOpen(false);
                      closeNavigationOnMobile?.();
                    }
                  }}
                />
              )}
            </span>
          );
        } else {
          return null;
        }
      }
      case 'subNavList': {
        return (
          <span
            key={nav.page_id}
            ref={moreMenuRef}
            className="cfeNavItems__item cfeNavItem-padding"
            onClick={() => onHandlePageClick(nav)}
          >
            <div>
              {nav.page_name}
              {nav?.subNavs?.length > 0 && (
                <img
                  alt="dropdown button"
                  src="/assets/images/angleright.png"
                  style={{
                    transform: `rotate(${horizontalNav ? '90deg' : '0deg'})`,
                  }}
                />
              )}
            </div>
            {open.openMore && navSubList(nav.subNavs)}
          </span>
        );
      }
      case 'about-us': {
        return (
          <span
            key={nav.page_id}
            className="cfeNavItems__item"
            onClick={() => onHandlePageClick(nav)}
          >
            <div>
              <div style={{ marginRight: 20 }} className="visible-mobile">
                <HeaderInfoIcon
                  color={
                    storeData?.theme?.colors?.primary_color !== '#ffffff'
                      ? '#000000'
                      : '#ffffff'
                  }
                />
              </div>
              <div>{nav.page_name}</div>
            </div>
          </span>
        );
      }
      case 'home': {
        return (
          <span
            key={nav.page_id}
            className="cfeNavItems__item"
            onClick={() => onHandlePageClick(nav)}
          >
            <div>
              <div className="mr3 visible-mobile" style={{ marginLeft: -1 }}>
                <HeaderHome
                  color={
                    storeData?.theme?.colors?.primary_color !== '#ffffff'
                      ? '#000000'
                      : '#ffffff'
                  }
                />
              </div>
              <div>{nav.page_name}</div>
            </div>
          </span>
        );
      }
      case 'blank_page': {
        return nav.is_external_url ? (
          <span
            key={nav.page_id}
            className="cfeNavItems_item mb3 cfeNavItems-padding cfeNavItems-margin"
          >
            <a
              style={{
                color: '#000000',
                fontSize: 14,
              }}
              className="cfeNavItem-padding mr3 link"
              href={nav.slug_url}
              target="_blank"
              rel="noreferrer"
            >
              {nav.page_name}
            </a>
          </span>
        ) : (
          <span
            key={nav.page_id}
            className="cfeNavItems__item cfeNavItem-padding"
            onClick={() => onHandlePageClick(nav)}
          >
            {nav.page_name}
          </span>
        );
      }
      default: {
        return (
          <span
            key={nav.page_id}
            className="cfeNavItems__item cfeNavItem-padding"
            onClick={() => onHandlePageClick(nav)}
          >
            {nav.page_name}
          </span>
        );
      }
    }
  };

  return (
    <div
      className={`cfeNavItems ${horizontalNav ? '' : 'vertical-nav'} ${
        isFooter ? 'footer-nav-style' : ''
      }`}
    >
      {navItemsState.map((nav) => renderNavType(nav))}
    </div>
  );
};

export default NavigationItems;
