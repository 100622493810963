import getCustomCtaText from './getCustomCtaText';
import store from '@/redux/store';

function getOrdersDropdownList() {
  const ordersDropdownList = [
    {
      id: 1,
      name: '000000000',
      action: 'defaultPhone',
    },
    {
      id: 2,
      name: getCustomCtaText('my_orders_cta'),
      action: 'orders',
    },
    {
      id: 3,
      name: 'Logout',
      action: 'logout',
    },
  ];

  const userContact =
    store?.getState()?.userReducer?.data?.phone ||
    store?.getState()?.userReducer?.data?.email;
  ordersDropdownList[0].name = userContact;

  const isVisitorLoginVisible =
    store?.getState()?.commonReducer?.featureLocksData?.featureLocks?.['visitorLogin']
      ?.isVisible;

  return ordersDropdownList.slice(0, !isVisitorLoginVisible ? 2 : undefined);
}

export default getOrdersDropdownList;
